import React, { useEffect, useState } from 'react';

const Loader = () => {
  const [isActive, setIsActive] = useState(false);

  // Set isActive to false after a certain time (e.g., 2 seconds)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsActive(true);
    }, 50);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="loader-container bg-customblue-500">
      {/* Your animated SVG goes here */}
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 165.07 165.07"
        width="165.07000732421875"
        height="165.07000732421875"
        className={`custom-svg ${isActive ? 'active' : ''}`}
      >
        <polyline
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="6px"
          className="cls-2 svg-elem-2"
          points="54.45 131.76 38.75 131.76 38.75 68.95 82.38 25.32 126.33 69.26 126.33 127.16 52.92 54.78 52.92 31.42"
        ></polyline>
      </svg>
    </div>
  );
};

export default Loader;
