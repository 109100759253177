import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import gbTranslation from './locales/gb.json';
import grTranslation from './locales/gr.json';


i18n
  .use(initReactI18next)
  .init({
    resources: {
      gb: {
        translation: gbTranslation,
      },
      gr: {
        translation: grTranslation,
      },
    },

    fallbackLng: 'gr',
    debug: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

  });

export default i18n;
