import React from "react";
import LanguageSwitcher from "./LanguageSwitcher";

export default function Footer(props) {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="flex justify-around items-center h-10 bg-customblue-500 text-white text-xs z-10 rounded-t-lg ml-2 mr-2" >
      <a href="https://www.iarts.gr/" target="_blank" rel="external" className="hover:text-customblue-300" aria-label="Powered by iarts, web and art design, Copyright © 2023">{props.text} {currentYear}</a>
      <LanguageSwitcher />
    </footer>
  );
}
