import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

const appConfig = window.globalConfig;
const YOUR_SITE_KEY = appConfig.recaptcha3;


const ContactForm = () => {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState('');

  const recaptchaRef = React.createRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Execute reCAPTCHA to get the response
    recaptchaRef.current.execute();

  };

  const handleCaptchaVerify = async (response) => {
    // Set the reCAPTCHA response in the state
    setRecaptchaValue(response);
  
    // Now, you can proceed with your form submission using recaptchaValue
    // For example, you can include it in your axios request data.
    try {
      const response = await axios.post('/send-email.php', {
        fullName,
        email,
        phone,
        message,
        recaptchaValue, // Include the reCAPTCHA response
      });
  
      // Handle the response as needed
      if (response.data === 'Email sent successfully') {
        setSuccessMessage('Email sent successfully');
        setErrorMessage('');
      } else {
        setErrorMessage('Email sending failed');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Email sending failed');
      setSuccessMessage('');
    }
  };
  

  return (
    <div className="flex items-center justify-center w-full h-full">
      <div className="w-full max-w-md px-4">
        <div className="w-full h-auto pl-2 mt-1 mb-1 2xl:mt-2 2xl:mb-2 bg-white rounded bg-opacity-80">
          <h3 className="text-md 2xl:text-lg font-bold text-customblue-500">{t("contact")}</h3>
        </div>
        <form className="px-4 pt-4 pb-4 mb-1 2xl:mb-4 text-xs 2xl:text-sm bg-white rounded shadow" onSubmit={handleSubmit}>

        <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={YOUR_SITE_KEY} // Replace with your reCAPTCHA Site Key
            size="invisible"
            onChange={handleCaptchaVerify} // Callback for reCAPTCHA verification
          />
                 
          <div className="mb-4">
            <label className="block mb-2 text-xs 2xl:text-sm font-bold text-gray-700" htmlFor="fullName">
              {t("fullname")}
            </label>
            <input
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="fullName"
              type="text"
              placeholder={t("fullname")}
              required
              onChange={(e) => setFullName(e.target.value)}
              aria-label={t("aria-fullname")}
              aria-required="true"
            />
          </div>

          <div className="flex flex-col justify-between md:flex-row xl:flex-col">
            <div className="mb-4">
              <label className="block mb-2 text-xs 2xl:text-sm font-bold text-gray-700" htmlFor="email">
                {t("email")}
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder={t("email")}
                required
                onChange={(e) => setEmail(e.target.value)}
                aria-label={t("aria-email")}
                aria-required="true"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2 text-xs 2xl:text-sm font-bold text-gray-700" htmlFor="phone">
                {t("phone")}
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="phone"
                type="tel"
                minlength="10"
                placeholder={t("phone")}
                required
                onChange={(e) => setPhone(e.target.value)}
                aria-label={t("aria-phone")}
                aria-required="true"
              />
            </div>
          </div>


          <div className="mb-4">
            <label className="block mb-2 text-xs 2xl:text-sm font-bold text-gray-700" htmlFor="message">
              {t("message")}
            </label>
            <textarea
              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              id="message"
              rows="4"
              placeholder={t("message")}
              onChange={(e) => setMessage(e.target.value)}
              aria-label={t("aria-message")}
            />
          </div>
          <div className="block mb-4 text-xs 2xl:text-sm text-gray-700">
            <p className="cursor-default">{t("accept_the")}<a href={appConfig.terms_url} target="_blank" rel="external" className="hover:text-customblue-300">{t("terms")}</a></p>
          </div>
          <div className="text-center">
            <button
              className="bg-customblue-500 hover:bg-customblue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit" aria-label={t("aria-submit")}
            >
              {t("submit")}
            </button>
          </div>
          {successMessage || errorMessage ? (
            <div className={`flex rounded-md justify-center align-middle items-center p-2 m-4 ${successMessage ? 'bg-green-100' : errorMessage ? 'bg-red-100' : ''}`}>
              {successMessage && <div><p className="text-green-500">{t("success")}</p></div>}
              {errorMessage && <div><p className="text-red-500">{t("failure")}</p></div>}
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
