import React, { useState, useEffect } from 'react';
import './App.css';
import SlidingColumns from './components/SlidingColumns';
import Footer from './components/Footer';
import "@fontsource/roboto";
import { useTranslation } from 'react-i18next';
import Loader from './components/Loader'; // Import the Loader component

function App() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading with a timeout
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the delay duration (in milliseconds)
  }, []);

  return (
    <div className="flex flex-col h-auto md:h-screen w-screen overflow-hidden bg-gray-100 ">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <SlidingColumns />
          <Footer text={t("copyright")} />
        </>
      )}
    </div>
  );
}

export default App;
