import React, { useState, useEffect } from "react";
import ContactForm from "./ContactForm";
import * as Icon from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';

function SlidingColumns() {
  const { t } = useTranslation();
  const [leftActive, setLeftActive] = useState(false);
  const [rightActive, setRightActive] = useState(false);
  const appConfig = window.globalConfig;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLeftActive(true);
      setRightActive(true);
    }, 50);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="flex grow">
      <div className="flex grow m-2 flex-col md:flex-row">
        <div
          className={`pb-4 pt-4 md:mr-1 flex justify-center items-center w-full lg:w-1/2 lg:h-full bg-white rounded-lg transition-transform ease-in-out duration-1000 ${leftActive ? "transform md:translate-y-0" : "transform md:-translate-y-full"}`}
        >
          <div className="flex items-center flex-col">
            <img className="w-1/3 sm:w-1/6" src="images/logo_sq.png" alt="Now-Real-Estate-Logo" width={100} height={100} />
            <h1 className="text-customblue-500 text-lg 2xl:text-3xl font-bold">{appConfig.office}</h1>
            <h2 className="text-customblue-500 text-base 2xl:text-xl font-bold">{t("subtitle")}</h2>
            <div className="p-5 md:w-4/5 text-xs 2xl:text-base text-justify">
              <p>{t("section1")}</p>
              <p>{t("section2")}</p>
              <p>{t("section3")}</p>
              <p>{t("section4")}</p>
            </div>
            <a href={appConfig.office_url} rel="external" className="bg-customblue-500 hover:bg-customblue-300 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-xs 2xl:text-base" aria-label={t("visit")}>
              <h3>{t("visit")}</h3>
            </a>
          </div>
        </div>

        <div
          className={`bg-hero mt-2 md:mt-0 md:ml-1 flex flex-col justify-center items-center w-full lg:w-1/2 lg:h-full bg-white rounded-lg transition-transform ease-in-out duration-1000 ${rightActive ? "transform md:translate-y-0" : "transform md:translate-y-full"}`}
        >
          <ContactForm />
          <div className="px-4 w-full">
            <div className="flex flex-row justify-around w-full h-auto mb-1 2xl:mb-4 bg-white py-2 bg-opacity-80 rounded">
              <div className="flex flex-col text-xs 2xl:text-sm">
                <h3 className="text-customblue-500 text-base 2xl:text-lg font-bold">{t("contactinfo")}</h3>
                <div className="flex flex-col"> 
                  <div className="flex flex-row items-center"><Icon.GeoAlt className="w-7 h-7 m-2 p-1 text-customblue-500" /><a href={appConfig.address_map} target="_blank" rel="external" className="hover:text-customblue-300 hover:cursor-pointer"><span>{t("address")}</span></a></div>
                  <div className="flex flex-row items-center"><Icon.Telephone className="w-7 h-7 m-2 p-1 text-customblue-500" /><a href="tel:{appConfig.phone}" target="_blank" rel="noreferrer" className="hover:text-customblue-300 hover:cursor-pointer"><span>{appConfig.phone}</span></a></div>
                  <div className="flex flex-row items-center"><Icon.Phone className="w-7 h-7 m-2 p-1 text-customblue-500" /><a href="tel:{appConfig.mobile}" target="_blank" rel="noreferrer" className="hover:text-customblue-300 hover:cursor-pointer"><span>{appConfig.mobile}</span></a></div>
                  <div className="flex flex-row items-center"><Icon.Envelope className="w-7 h-7 m-2 p-1 text-customblue-500" /><a href="mailto:{appConfig.email}" target="_blank" rel="noreferrer" className="hover:text-customblue-300 hover:cursor-pointer"><span>{appConfig.email}</span></a></div>
                  <div className="flex flex-row items-center"><Icon.FileEarmark className="w-7 h-7 m-2 p-1 text-customblue-500" /><span className="hover:cursor-default">{t("gemi")}{appConfig.gemi}</span></div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h3 className="text-customblue-500 text-base 2xl:text-lg font-bold">{t("social")}</h3>
                <div className="flex flex-row"> 
                  <a href={appConfig.facebook} target="_blank" rel="nofollow"><Icon.Facebook className="w-7 h-7 m-2 p-1 text-customblue-500 hover:text-customblue-300" aria-label="facebook button" /></a>
                  <a href={appConfig.instagram} target="_blank" rel="nofollow"><Icon.Instagram className="w-7 h-7 m-2 p-1 text-customblue-500 hover:text-customblue-300" aria-label="instagram button" /></a>
                  <a href={appConfig.linkedin} target="_blank" rel="nofollow"><Icon.Linkedin className="w-7 h-7 m-2 p-1 text-customblue-500 hover:text-customblue-300" aria-label="linkedin button" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SlidingColumns;
