import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const languages = [
    { code: 'gb', name: 'English' },
    { code: 'gr', name: 'Ελληνικά' },
  ];

  return (
    <div>
      {languages.map((language) => (
        <button
          key={language.code}
          onClick={() => changeLanguage(language.code)}
        >
          <ReactCountryFlag
            className="rounded-full bg-white"
            countryCode={language.code}
            alt={language.code}
            aria-label={language.code}
            svg
            style={{
              width: '2em',
              height: '2em',
              marginRight: '1em',
            }}
          />
          {/* language.name */}
        </button>
      ))}
    </div>
  );
}

export default LanguageSwitcher;
